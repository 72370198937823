import React from 'react'
import { Button } from './../Button'
import { SectionTitle } from './../../components/SectionTitle'
import card1 from './../../images/card-1.png'
import card2 from './../../images/card-2.png'

export const CompanyCards = () => {
  return (
    <>
      <SectionTitle title="Fuel cards overview" />
      <ul className="company-cards__list">
        <li>
          <div className="company-cards__info">
            <img src={card1} alt="" />
            <h2 className="company-cards__balance">
              1 495,58 PLN
              <span>-30,00 PLN</span>
            </h2>
          </div>
          <div className="company-cards__buttons">
            <Button text="Top up" disabled={true} version="primary" />
            <Button text="More" disabled={true} version="secondary" />
          </div>
        </li>
        <li>
          <div className="company-cards__info">
            <img src={card2} alt="" />
            <h2 className="company-cards__balance">
              1 495,58 PLN
              <span>-30,00 PLN</span>
            </h2>
          </div>
          <div className="company-cards__buttons">
            <Button text="Top up" disabled={true} version="primary" />
            <Button text="More" disabled={true} version="secondary" />
          </div>
        </li>
      </ul>
    </>
  )
}
