import React from 'react'
import Copy from '../../assets/svgs/copy.svg'
import More from '../../assets/svgs/more-vertical.svg'

export const AccountCard = ({
  account: { name, number, balance, balanceDecimal, logo },
}) => {
  return (
    <div className="account-card">
      <div className="account-card__details">
        <div>
          <span className="account-card__bank-name">{name}</span>
          <span className="account-card__number">
            {number}{' '}
            <span className="account-card__icon-wrapper">
              <Copy />
            </span>
          </span>
        </div>
        <img src={logo} alt={name} />
      </div>
      <div className="account-card__balance-wrapper">
        <div className="account-card__balance">
          {balance}
          <span>{balanceDecimal} PLN</span>
        </div>
        <div className="account-card__icon-wrapper">
          <More />
        </div>
      </div>
    </div>
  )
}
