import React from 'react'
import fuelIcon from './../../images/svg/fuel-icon.svg'

export const FuelCards = () => {
  return (
    <div className="fuel-cards">
      <img src={fuelIcon} alt="" />
      <h2>Fuel Cards Overview</h2>
    </div>
  )
}
