import React, { useState } from 'react'
import { SectionTitle } from './../../components/SectionTitle'
import { SpendingGraphDetails } from './../SpendingGraphDetails'
import { spendingsContent } from './../../content/spendings'

export const SpendingsGraph = () => {
  const [detailsDisplayed, setDetailsDisplayed] = useState(false)
  const [selectedDetail, setSelectedDetail] = useState(0)

  const toggleDetailsDisplayed = (detailID = null) => {
    setDetailsDisplayed(!detailsDisplayed)
    if (detailID !== null) setSelectedDetail(detailID)
  }

  return (
    <>
      <SectionTitle title="Company spendings" />
      <div className="spendings-graph">
        <div className="spendings-graph__head">
          <h3 className="spendings-graph__income">
            <span className="spendings-graph__label">Income</span>
            85 958,95 <span className="spendings-graph__currency">PLN</span>
          </h3>
          <h3 className="spendings-graph__expenses">
            <span className="spendings-graph__label">Expenses</span>
            737 958,95 <span className="spendings-graph__currency">PLN</span>
          </h3>
        </div>
        <span className="spendings-graph__average">
          <span>avg.</span>
        </span>
        <ul className="spendings-graph__main">
          {
            spendingsContent.length && (
              spendingsContent.map(({ month, income, expenses }, index) => (
                <li className="spendings-graph__single" key={month} onClick={() => toggleDetailsDisplayed(index)}>
                  <h4 className="spendings-graph__plus">
                    <span>Income</span>
                    {income.value}
                  </h4>
                  <h4 className="spendings-graph__minus">
                    <span>Expenses</span>
                    {expenses.value}
                  </h4>
                  <div className="spendings-graph__block-income" style={{ height: income.height }}></div>
                  <div className="spendings-graph__block-expenses" style={{ height: expenses.height }}></div>
                  <span className="spendings-graph__month">{ month }</span>
                </li>
              ))
            )
          }
        </ul>
      </div>
      <SpendingGraphDetails 
        isActive={detailsDisplayed}
        toggleDetails={toggleDetailsDisplayed}
        data={spendingsContent[selectedDetail]}
      />
    </>
  )
}
