import React from 'react'
import paymentsIcon from './../../images/svg/upcoming-payments-icon.svg'
import { SectionTitle } from './../../components/SectionTitle'

export const UpcomingPayments = () => {
  return (
    <>
      <SectionTitle title="Upcoming payments" />
      <ul className="upcoming-payments">
        <li>
          <div className="upcoming-payments__info">
            <img src={paymentsIcon} alt="" />
            <h2 className="upcoming-payments__data">
              Drukpol
              <span>Invoice no 483/43/2020</span>
            </h2>
          </div>
          <div className="upcoming-payments__details">
            <h3 className="upcoming-payments__cost">
              -43 586,48 PLN
            </h3>
            <p className="upcoming-payments__ago">
              1 days ago
            </p>
          </div>
        </li>
        <li>
          <div className="upcoming-payments__info">
            <img src={paymentsIcon} alt="" />
            <h2 className="upcoming-payments__data">
              Polpex
              <span>Invoice no 483/43/2020</span>
            </h2>
          </div>
          <div className="upcoming-payments__details">
            <h3 className="upcoming-payments__cost">
              -3 586,48 PLN
            </h3>
            <p className="upcoming-payments__ago">
              1 days ago
            </p>
          </div>
        </li>
        <li>
          <div className="upcoming-payments__info">
            <img src={paymentsIcon} alt="" />
            <h2 className="upcoming-payments__data">
              Witchen
              <span>Invoice no 483/43/2020</span>
            </h2>
          </div>
          <div className="upcoming-payments__details">
            <h3 className="upcoming-payments__cost">
              -11 586,48 PLN
            </h3>
            <p className="upcoming-payments__ago">
              1 days ago
            </p>
          </div>
        </li>
        <li>
          <div className="upcoming-payments__info">
            <img src={paymentsIcon} alt="" />
            <h2 className="upcoming-payments__data">
              Castorama
              <span>Invoice no 483/43/2020</span>
            </h2>
          </div>
          <div className="upcoming-payments__details">
            <h3 className="upcoming-payments__cost">
              -586,48 PLN
            </h3>
            <p className="upcoming-payments__ago">
              1 days ago
            </p>
          </div>
        </li>
        <li>
          <div className="upcoming-payments__info">
            <img src={paymentsIcon} alt="" />
            <h2 className="upcoming-payments__data">
              Auchan
              <span>Invoice no 483/43/2020</span>
            </h2>
          </div>
          <div className="upcoming-payments__details">
            <h3 className="upcoming-payments__cost">
              -6,48 PLN
            </h3>
            <p className="upcoming-payments__ago">
              1 days ago
            </p>
          </div>
        </li>
      </ul>
    </>
  )
}
