import React from 'react'
import backIcon from './../../images/svg/back.svg'

export const SpendingGraphDetails = ({ isActive, toggleDetails, data }) => {
  return (
    <div className={`spendings-details ${isActive ? 'active' : ''}`}>
      <button
        className="spendings-details__back"
        onClick={() => toggleDetails()}
      >
        <img src={backIcon} alt="" />
        Back
      </button>
      <div className="spendings-details__data">
        <div className="spendings-details__content">
          <span className="spendings-details__label">Income</span>
          <h2 className="spendings-details__value spendings-details__value--frozen">
            {data?.income.value} <span>PLN</span>
          </h2>
        </div>
        <div
          className="spendings-details__block spendings-details__block--frozen"
          style={{ width: data?.income.height }}
        ></div>
      </div>
      <div className="spendings-details__data">
        <div className="spendings-details__content">
          <span className="spendings-details__label">Expenses</span>
          <h2 className="spendings-details__value spendings-details__value--red">
            {data?.expenses.value} <span>PLN</span>
          </h2>
        </div>
        <div
          className="spendings-details__block spendings-details__block--red"
          style={{ width: data?.expenses.height }}
        ></div>
      </div>
      <div className="spendings-details__buttons">
        <button>Request financing</button>
        <button>Consolidate data</button>
        <button>Postpone payment</button>
      </div>
    </div>
  )
}
