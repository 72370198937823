import React from 'react'
import { SiteLogo } from './../SiteLogo'
import { Button } from './../Button'
import { asideLinksContent } from './../../content/aside-links'

export const Aside = ({ location }) => {
  return (
    <aside className="dashboard-aside">
      <SiteLogo path="/dashboard" />
      <nav className="dashboard-aside__menu">
        <ul>
          {
            asideLinksContent.length && (
              asideLinksContent.map(({ icon, label, href }) => (
                <li key={label}>
                  <a 
                    href={href} 
                    className={`
                      ${!href ? 'dashboard-aside__link--disabled' : ''} 
                      ${href === location ? 'dashboard-aside__link--active' : ''}
                    `}
                  >
                    {icon()}
                    <span className="dashboard-aside__text">{label}</span>
                  </a>
                </li>
              ))
            )
          }
        </ul>
      </nav>
      <Button 
        text="Automate"
        version="primary"
        href="#"
        disabled={true}
      />
    </aside>
  )
}
