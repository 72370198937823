import React, { useState } from 'react'
import { Aside } from '../../components/Aside'
import { SearchBar } from './../../components/SearchBar'
import { ActionLinks } from './../../components/ActionLinks'
import { ProfileName } from './../../components/ProfileName'
import { Button } from './../../components/Button'
import { Balance } from './../../components/Balance'
import { CompanyCards } from './../../components/CompanyCards'
import { UpcomingPayments } from './../../components/UpcomingPayments'
import { SpendingsGraph } from './../../components/SpendingsGraph'
import { GatheringInformation } from '../../components/GatheringInformation'
import { ComponentPlaceholder } from '../../components/ComponentPlaceholder'
import { CustomizeAside } from '../../components/CustomizeAside'
import { FuelCards } from '../../components/FuelCards'
import { ComponentPlaceholderWrapper } from '../../components/ComponentPlaceholderWrapper'
import successWhiteIcon from '../../images/success-white.png'
import { AccountCard } from '../../components/AccountCard'
import santander from '../../images/santander.png'
import deutsche from '../../images/deutsche.png'
import citi from '../../images/citi.png'
import creditAgricole from '../../images/credit-agricole.png'

const ACCOUNTS = [
  {
    name: 'Santander',
    number: '37 **** 4591',
    balance: '12 234',
    balanceDecimal: ',07',
    logo: santander,
  },
  {
    name: 'CitiBank',
    number: '37 **** 4592',
    balance: '2 333',
    balanceDecimal: ',00',
    logo: citi,
  },
  {
    name: 'Deutsche Bank',
    number: '37 **** 4593',
    balance: '987 654',
    balanceDecimal: ',32',
    logo: deutsche,
  },
  {
    name: 'Credit Agricole',
    number: '37 **** 4594',
    balance: '0',
    balanceDecimal: ',99',
    logo: creditAgricole,
  },
]

export const DashboardLayout = ({ showCustomizationFirst }) => {
  const checkCustomComponent = () => {
    if (typeof window !== 'undefined') {
      return window.sessionStorage.getItem('custom-component-1') ? true : false
    } else {
      return false
    }
  }

  const [showGathering, setGathering] = useState(showCustomizationFirst)
  const [isCustomizeMode, setCustomMode] = useState(false)
  const [customComponent, setCustomComponent] = useState(checkCustomComponent())
  const [showSuccess, setShowSuccess] = useState(false)

  const handleShowGathering = () => {
    setGathering(!showGathering)
  }

  const handleToggleCustomMode = () => {
    setCustomMode(!isCustomizeMode)
  }

  const removeCustomComponent = () => {
    window.sessionStorage.removeItem('custom-component-1')
    setCustomComponent(false)
  }

  const handleSuccessCustomize = () => {
    if (isCustomizeMode) setShowSuccess(true)
    setCustomMode(!isCustomizeMode)

    setTimeout(() => {
      setShowSuccess(false)
    }, 2000)
  }

  return (
    <div className="dashboard">
      <Aside location={'/dashboard'} />
      <main className="dashboard-main">
        <section className="dashboard-main__section dashboard-main__section--top">
          <SearchBar />
          <ActionLinks />
          <ProfileName />
          <div className="custom-button-wrapper">
            <Button
              version="primary"
              text="Customize"
              handleClick={handleShowGathering}
              href="#"
            />
            <div className="custom-button-wrapper__desc">
              Quickly and easily create a custom dashboard layout to your needs
            </div>
          </div>
        </section>
        <section className="dashboard-main__section">
          {isCustomizeMode ? <ComponentPlaceholder /> : <Balance />}
        </section>
        <section className="dashboard-main__section">
          {customComponent ? (
            customComponent && isCustomizeMode ? (
              <ComponentPlaceholderWrapper handleRemove={removeCustomComponent}>
                <FuelCards />
              </ComponentPlaceholderWrapper>
            ) : (
              <CompanyCards />
            )
          ) : isCustomizeMode ? (
            <ComponentPlaceholder />
          ) : (
            <CompanyCards />
          )}
        </section>
        <section
          className={`dashboard-main__section dashboard-main__section--accounts-cards ${
            isCustomizeMode &&
            'dashboard-main__section--accounts-cards--customize'
          } `}
        >
          {isCustomizeMode ? (
            <ComponentPlaceholder />
          ) : (
            ACCOUNTS.map((account, i) => (
              <AccountCard account={account} key={account.number} />
            ))
          )}
        </section>
        <section className="dashboard-main__section">
          {isCustomizeMode ? <ComponentPlaceholder /> : <UpcomingPayments />}
        </section>
        <section className="dashboard-main__section">
          {isCustomizeMode ? <ComponentPlaceholder /> : <SpendingsGraph />}
        </section>
        {showGathering && (
          <GatheringInformation
            handleEndLoading={setGathering}
            handleShowPlaceholders={handleToggleCustomMode}
            setGathering={setGathering}
            firstLogin={showCustomizationFirst}
          />
        )}
        {isCustomizeMode && (
          <CustomizeAside
            setCustomComponent={setCustomComponent}
            handleToggleCustomMode={handleToggleCustomMode}
            handleSuccessCustomize={handleSuccessCustomize}
          />
        )}
      </main>
      <div
        className={`customize-aside__success ${showSuccess ? 'active' : ''}`}
      >
        <img src={successWhiteIcon} alt="" />
        <h2>Your banking dashboard has been changed</h2>
      </div>
    </div>
  )
}
