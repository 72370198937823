import React from 'react'
import { Link } from 'gatsby'

import successIcon from './../../images/success-gathering.png'
import { Button } from './../Button'

export const GatheringSuccess = ({
  handleEndLoading,
  handleShowPlaceholders,
  setGathering,
}) => {
  return (
    <div className="gathering-informations">
      <div className="gathering-informations__wrapper">
        <img src={successIcon} alt="" />
        <h2 className="gathering-informations__heading">Success!</h2>
        <p className="gathering-informations__desc">
          We’ve successfully managed to gather information about your company to
          create a unique layout
        </p>
        <p className="gathering-informations__gathered">
          Industry: <span>Transport</span>
        </p>
        <p className="gathering-informations__gathered">
          Profile type: <span>Foreign Trade Profile</span>
        </p>
        <Button
          text="Continue"
          version="frozen"
          handleClick={() => {
            handleEndLoading()
            handleShowPlaceholders()
          }}
          href="#"
        />
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          className="gathering-informations__cancel"
          to="/"
          onClick={() => setGathering(false)}
        >
          {' '}
          Cancel
        </Link>
      </div>
    </div>
  )
}
