import React from 'react'

export const ComponentPlaceholderWrapper = ({ children, handleRemove }) => {
  return (
    <div className={'component-placeholder-remove'}>
      <button onClick={handleRemove} className={'component-placeholder-remove__button'}>Remove</button>
      { children }
    </div>
  )
}
