import React from 'react'
import { SEO } from '../components/SEO'
import { DashboardLayout } from './../layouts/DashboardLayout'

const Dashboard = ({ location }) => (
  <>
    <SEO title="Dashboard | Future Bank" />
    <DashboardLayout
      showCustomizationFirst={
        location.state ? !!location.state.firstLogin : false
      }
    />
  </>
)

export default Dashboard
