import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'

import loader from './../../images/custom-loader.png'
import { customStepsContent } from '../../content/custom-steps'
import { GatheringSuccess } from './../GatheringSuccess'

const Info = ({ handleContinue }) => (
  <>
    <img
      className="gathering-informations__rotate gathering-informations__rotate--hidden"
      src={loader}
      alt="Please wait"
    />
    <h2 className="gathering-informations__heading">
      Let us customize your dashboard
    </h2>
    <p className="gathering-informations__desc">
      We will gather some essential information about your bussiness to unlock
      unique dashboard elements just for you. We will check following data:
    </p>
    <ul className="gathering-informations__steps">
      {customStepsContent.length &&
        customStepsContent.map((step, index) => (
          <li className="gathering-informations__step info" key={index}>
            {step}
          </li>
        ))}
    </ul>
    <button className="btn btn--frozen" onClick={handleContinue}>
      Continue
    </button>
    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
    <Link className="gathering-informations__cancel" to="/">
      Cancel
    </Link>
  </>
)

export const GatheringInformation = ({
  handleEndLoading,
  handleShowPlaceholders,
  setGathering,
  firstLogin,
}) => {
  const [currentStep, setCurrentStep] = useState(0)
  const [successful, setSuccessful] = useState(false)
  const [showInfoPage, setShowInfoPage] = useState(firstLogin)

  const startGathering = () => {
    customStepsContent.forEach((item, index) => {
      setTimeout(() => {
        setCurrentStep(index)
      }, 1000 * index)
    })
  }

  useEffect(() => {
    if (currentStep === customStepsContent.length - 1) {
      setTimeout(() => {
        setSuccessful(true)
      }, 1500)
    }
  }, [currentStep])

  useEffect(() => {
    !showInfoPage && startGathering()
  }, [showInfoPage])

  return (
    <section className="gathering-informations">
      <div className="gathering-informations__wrapper">
        {showInfoPage ? (
          <Info handleContinue={() => setShowInfoPage(false)} />
        ) : !successful ? (
          <>
            <img
              className="gathering-informations__rotate"
              src={loader}
              alt="Please wait"
            />
            <h2 className="gathering-informations__heading">
              Gathering your business profile
            </h2>
            <p className="gathering-informations__desc">
              We’re currently gathering information about your business, which
              will help create a unique layout to your needs.
            </p>
            <ul className="gathering-informations__steps">
              {customStepsContent.length &&
                customStepsContent.map((step, index) => (
                  <li
                    className={`gathering-informations__step ${
                      currentStep >= index ? 'active' : ''
                    }`}
                    key={index}
                  >
                    {step}
                  </li>
                ))}
            </ul>
          </>
        ) : (
          <GatheringSuccess
            handleEndLoading={handleEndLoading}
            handleShowPlaceholders={handleShowPlaceholders}
            setGathering={setGathering}
          />
        )}
      </div>
    </section>
  )
}
