import fuelIcon from './../images/svg/fuel-icon.svg'
import truckIcon from './../images/svg/truck-insurance-icon.svg'
import fineIcon from './../images/svg/fine-management-icon.svg'
import invoicesIcon from './../images/svg/invoices-overview-icon.svg'
import manageIcon from './../images/svg/manage-cards-icon.svg'
import receiptsIcon from './../images/svg/receipts-icon.svg'

export const customComponentsContent = [
  {
    name: 'Fuel Cards Overview',
    icon: fuelIcon
  },
  {
    name: 'Truck Insurance Management',
    icon: truckIcon
  },
  {
    name: 'Fine management',
    icon: fineIcon
  },
  {
    name: 'Invoices overview',
    icon: invoicesIcon
  },
  {
    name: 'Manage your cards',
    icon: manageIcon
  },
  {
    name: 'Receipts and invoices',
    icon: receiptsIcon
  }
]