import React from 'react'
import avatar from './../../images/avatar.png'

export const ProfileName = () => {
  return (
    <div className="profile-name">
      <div className="profile-name__content">
        <h2 className="profile-name__surname">
          Jan Kowalski
        </h2>
        <p className="profile-name__position">
          Business account
        </p>
      </div>
      <img src={avatar} alt="Jan Kowalski" /> 
    </div>
  )
}
