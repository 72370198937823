import React from 'react'
import backIcon from './../../images/svg/back.svg'
import dragIcon from './../../images/svg/drag-icon.svg'
import addIcon from './../../images/svg/add-icon.svg'
import { customComponentsContent } from './../../content/custom-components'

export const CustomizeAside = ({
  setCustomComponent,
  handleToggleCustomMode,
  handleSuccessCustomize,
}) => {
  const handleSelect = () => {
    sessionStorage.setItem('custom-component-1', 'fuel')
    setCustomComponent(true)
  }

  return (
    <section className="customize-aside">
      <button
        className="customize-aside__back"
        onClick={handleToggleCustomMode}
      >
        <img src={backIcon} alt="" />
        Back
      </button>
      <h2 className="customize-aside__head">Customize your dashboard</h2>
      <p className="customize-aside__desc">
        You can customise the layout by dragging and dropping.
      </p>
      <p className="customize-aside__drag">
        <img src={dragIcon} alt="" />
        Drag and drop
      </p>
      <ul className="customize-aside__components">
        {customComponentsContent.length &&
          customComponentsContent.map(({ name, icon }, index) => (
            <li
              key={name}
              className={`customize-aside__component ${
                !index ? 'available' : ''
              }`}
              onClick={!index ? handleSelect : null}
            >
              <img src={icon} alt={name} />
              {name}
              <button className="customize-aside__add">
                <img src={addIcon} alt="" />
              </button>
            </li>
          ))}
      </ul>
      <button
        className="customize-aside__confirm"
        onClick={handleSuccessCustomize}
      >
        Confirm
      </button>
    </section>
  )
}
