import React from 'react'
import dragIcon from './../../images/svg/drag-component.svg'

export const ComponentPlaceholder = () => {
  return (
    <div className="component-placeholder">
      <img src={dragIcon} alt="" />
      <h2 className="component-placeholder__title">
        Drop here
      </h2>
    </div>
  )
}
