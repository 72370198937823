import React from 'react'

export const asideLinksContent = [
  {
    icon: () => (
      <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.39392 24.0247C8.40401 24.0214 8.41081 24.0119 8.41081 24.0013V20.1892C8.41081 19.2116 9.20916 18.419 10.194 18.419H13.794C14.2669 18.419 14.7204 18.6055 15.0548 18.9375C15.3893 19.2695 15.5771 19.7198 15.5771 20.1892V24.0191C15.5741 24.4256 15.7347 24.8164 16.0231 25.1049C16.3116 25.3933 16.7041 25.5555 17.1136 25.5555H19.5696C20.7167 25.5585 21.8178 25.1082 22.63 24.304C23.4421 23.4999 23.8986 22.4079 23.8986 21.2692V10.3585C23.8986 9.43862 23.4878 8.56609 22.7771 7.97593L14.422 1.35158C12.9686 0.190103 10.8862 0.227604 9.47622 1.44065L1.31179 7.97593C0.567453 8.54869 0.122571 9.42381 0.100586 10.3585V21.258C0.100586 23.6315 2.03872 25.5555 4.42953 25.5555H6.82951C7.67394 25.5555 8.36131 24.8839 8.37694 24.048C8.37713 24.0374 8.38391 24.028 8.39392 24.0247Z" fill="#9FA7B4"/>
      </svg>
    ),
    label: 'Dashboard',
    href: '/dashboard'
  },
  {
    icon: () => (
      <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.2496 0.505005C21.1186 0.505005 23.273 2.7345 23.273 6.5547V19.4933C23.273 23.3761 21.1186 25.5555 17.2496 25.5555H6.75218C2.94451 25.5555 0.727539 23.3761 0.727539 19.4933V6.5547C0.727539 2.7345 2.94451 0.505005 6.75218 0.505005H17.2496ZM7.09037 17.7147C6.71461 17.6771 6.35138 17.8525 6.15097 18.1781C5.95057 18.4913 5.95057 18.9046 6.15097 19.2303C6.35138 19.5434 6.71461 19.7313 7.09037 19.6812H16.9102C17.4099 19.6311 17.7869 19.204 17.7869 18.7042C17.7869 18.1907 17.4099 17.7648 16.9102 17.7147H7.09037ZM16.9102 12.0019H7.09037C6.55053 12.0019 6.1134 12.4416 6.1134 12.9802C6.1134 13.5187 6.55053 13.9571 7.09037 13.9571H16.9102C17.4487 13.9571 17.8871 13.5187 17.8871 12.9802C17.8871 12.4416 17.4487 12.0019 16.9102 12.0019ZM10.8342 6.32925H7.09037V6.34177C6.55053 6.34177 6.1134 6.78016 6.1134 7.31874C6.1134 7.85733 6.55053 8.29571 7.09037 8.29571H10.8342C11.374 8.29571 11.8124 7.85733 11.8124 7.30496C11.8124 6.76763 11.374 6.32925 10.8342 6.32925Z" fill="#9FA7B4"/>
      </svg>
    ),
    label: 'Invoices',
    href: null
  },
  {
    icon: () => (
      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.3211 2.73491C17.271 3.04804 17.2459 3.36117 17.2459 3.6743C17.2459 6.49248 19.5255 8.77082 22.3312 8.77082C22.6443 8.77082 22.9449 8.7345 23.258 8.6844V18.791C23.258 23.0383 20.753 25.5559 16.4944 25.5559H7.2395C2.97966 25.5559 0.474609 23.0383 0.474609 18.791V9.52359C0.474609 5.26501 2.97966 2.73491 7.2395 2.73491H17.3211ZM17.5728 10.3503C17.2334 10.3127 16.8965 10.463 16.6948 10.7385L13.665 14.6589L10.1942 11.9284C9.98128 11.7656 9.73077 11.7017 9.48027 11.728C9.23101 11.7656 9.00556 11.9021 8.854 12.1025L5.14778 16.926L5.07138 17.0387C4.85845 17.4383 4.95865 17.9518 5.33441 18.2286C5.50976 18.3414 5.69764 18.4165 5.91057 18.4165C6.1999 18.429 6.47421 18.2775 6.64956 18.0408L9.7934 13.9939L13.3631 16.6755L13.4758 16.7494C13.8766 16.9623 14.3776 16.8634 14.6657 16.4864L18.2855 11.8157L18.2354 11.8408C18.4358 11.5652 18.4734 11.2145 18.3356 10.9014C18.1991 10.5882 17.8972 10.3753 17.5728 10.3503ZM22.5067 0.505035C24.1725 0.505035 25.5252 1.85776 25.5252 3.52362C25.5252 5.18948 24.1725 6.54221 22.5067 6.54221C20.8408 6.54221 19.4881 5.18948 19.4881 3.52362C19.4881 1.85776 20.8408 0.505035 22.5067 0.505035Z" fill="#9FA7B4"/>
      </svg>
    ),
    label: 'Payment feed',
    href: null
  },
  {
    icon: () => (
      <svg width="26" height="20" viewBox="0 0 26 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="0.788086" y="0.515167" width="24.4242" height="18.7879" rx="5.63636" fill="#9FA7B4"/>
        <rect x="0.788086" y="6.15152" width="24.4242" height="2.81818" fill="white"/>
      </svg>
    ),
    label: 'Your cards',
    href: null
  },
  {
    icon: () => (
      <svg width="22" height="26" viewBox="0 0 22 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.30484 17.4159C10.1081 17.2706 11.9196 17.2706 13.7228 17.4159C14.7047 17.4732 15.682 17.596 16.6485 17.7837C18.7398 18.2071 20.1052 18.898 20.6903 20.0124C21.1298 20.8821 21.1298 21.9174 20.6903 22.7871C20.1052 23.9014 18.794 24.6369 16.6268 25.0158C15.6611 25.2105 14.6836 25.3371 13.7011 25.3947C12.7909 25.4949 11.8807 25.4949 10.9596 25.4949H9.30174C8.955 25.4504 8.61908 25.4281 8.294 25.4281C7.31148 25.3775 6.33374 25.2547 5.36831 25.0603C3.27698 24.6592 1.91165 23.946 1.32651 22.8317C1.10063 22.4 0.981539 21.9178 0.979623 21.4276C0.975053 20.9344 1.09054 20.4479 1.31567 20.0124C1.88998 18.898 3.2553 18.1737 5.36831 17.7837C6.338 17.5933 7.31907 17.4705 8.30484 17.4159ZM11.003 0.444443C14.6356 0.444443 17.5804 3.47283 17.5804 7.20852C17.5804 10.9442 14.6356 13.9726 11.003 13.9726C7.37038 13.9726 4.42558 10.9442 4.42558 7.20852C4.42558 3.47283 7.37038 0.444443 11.003 0.444443Z" fill="#9FA7B4"/>
      </svg>
    ),
    label: 'Permissions',
    href: null
  },
  {
    icon: () => (
      <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.88956 14.8105C9.65562 14.8105 11.071 16.2397 11.071 18.0182V22.2881C11.071 24.0542 9.65562 25.4946 7.88956 25.4946H3.65602C1.90249 25.4946 0.474609 24.0542 0.474609 22.2881V18.0182C0.474609 16.2397 1.90249 14.8105 3.65602 14.8105H7.88956ZM22.3438 14.8105C24.0974 14.8105 25.5252 16.2397 25.5252 18.0182V22.2881C25.5252 24.0542 24.0974 25.4946 22.3438 25.4946H18.1103C16.3442 25.4946 14.9289 24.0542 14.9289 22.2881V18.0182C14.9289 16.2397 16.3442 14.8105 18.1103 14.8105H22.3438ZM7.88956 0.444435C9.65562 0.444435 11.071 1.88484 11.071 3.65215V7.92201C11.071 9.70059 9.65562 11.1285 7.88956 11.1285H3.65602C1.90249 11.1285 0.474609 9.70059 0.474609 7.92201V3.65215C0.474609 1.88484 1.90249 0.444435 3.65602 0.444435H7.88956ZM22.3438 0.444435C24.0974 0.444435 25.5252 1.88484 25.5252 3.65215V7.92201C25.5252 9.70059 24.0974 11.1285 22.3438 11.1285H18.1103C16.3442 11.1285 14.9289 9.70059 14.9289 7.92201V3.65215C14.9289 1.88484 16.3442 0.444435 18.1103 0.444435H22.3438Z" fill="#9FA7B4"/>
      </svg>
    ),
    label: 'Services',
    href: null
  },
  {
    icon: () => (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.0012 0.166647C15.1118 0.166647 18.1061 1.3996 20.3019 3.60718C22.5095 5.81593 23.7436 8.79733 23.7436 11.9208C23.7436 16.0307 21.5819 19.8469 18.0591 21.9723C14.5364 24.0977 10.1565 24.2151 6.5175 22.2776H6.4811C6.13 22.1367 5.84701 21.8901 5.51822 21.7257C5.15538 21.5731 4.74322 21.5496 4.36864 21.6682C3.49852 21.9723 2.61784 22.2189 1.71368 22.4303C1.24398 22.442 1.10307 22.1602 1.10307 21.7022C1.31443 20.7746 1.59625 19.8575 1.92621 18.9663C2.05421 18.5788 2.02015 18.1678 1.82053 17.8026L1.58568 17.3458C0.715569 15.6901 0.258789 13.8466 0.258789 11.9807V11.9091C0.258789 8.79733 1.49057 5.80301 3.69814 3.60718C5.90572 1.3996 8.8883 0.166647 12.0012 0.166647ZM17.4133 10.4178C16.5913 10.4178 15.9114 11.0871 15.9114 11.9208C15.9114 12.7428 16.5913 13.4238 17.4133 13.4238C18.247 13.4238 18.9163 12.7428 18.9163 11.9208C18.9163 11.0871 18.247 10.4178 17.4133 10.4178ZM12.0012 10.4178C11.1675 10.4178 10.497 11.0871 10.497 11.9208C10.497 12.7428 11.1675 13.4238 12.0012 13.4238C12.8349 13.4238 13.5031 12.7428 13.5031 11.9208C13.5031 11.0871 12.8349 10.4178 12.0012 10.4178ZM6.58678 10.4178C5.75307 10.4178 5.08375 11.0871 5.08375 11.9208C5.08375 12.7428 5.75307 13.4238 6.58678 13.4238C7.40875 13.4238 8.09098 12.7428 8.09098 11.9208C8.09098 11.0871 7.40875 10.4178 6.58678 10.4178Z" fill="#9FA7B4"/>
      </svg>
    ),
    label: 'Assistent',
    href: null
  },
]