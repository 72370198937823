import React from 'react'

export const SectionTitle = ({ title }) => {
  return (
    <div className="section-head">
      <h2 className="section-head__title">
        { title }
      </h2>
      <button>See all</button>
    </div>
  )
}
