import React from 'react'

import { Button } from './../Button'
import Arrow from '../../assets/svgs/arrow-right.svg'

export const Balance = () => {
  return (
    <div className="account-balance">
      <div className="account-balance__balance-info">
        <div className="account-balance__account-info">
          <h2 className="account-balance__title">Main account</h2>
          <h3 className="account-balance__account-name">
            10CloudsBank Account
          </h3>
          <p className="account-balance__number">
            PL 37 7485 3749 4855 9847 4591
            <Arrow />
          </p>
        </div>

        <div className="account-balance__balance">
          <h2 className="account-balance__title">Available funds</h2>
          <h3 className="account-balance__value">
            63 859<span>,94 PLN</span>
          </h3>
        </div>
      </div>

      <div className="account-balance__buttons">
        <Button
          text="Transfer money"
          version="primary"
          disabled={true}
          href="#"
        />
        <Button
          text="Link accounts"
          version="secondary"
          disabled={true}
          href="#"
        />
      </div>
    </div>
  )
}
