export const spendingsContent = [
  {
    month: 'I',
    income: {
      value: '43 586,12',
      height: '20%',
    },
    expenses: {
      value: '53 111,34',
      height: '23%'
    }
  },
  {
    month: 'II',
    income: {
      value: '93 586,12',
      height: '30%',
    },
    expenses: {
      value: '53 111,34',
      height: '23%'
    }
  },
  {
    month: 'III',
    income: {
      value: '43 586,12',
      height: '25%',
    },
    expenses: {
      value: '53 111,34',
      height: '17%'
    }
  },
  {
    month: 'IV',
    income: {
      value: '43 586,12',
      height: '40%',
    },
    expenses: {
      value: '53 111,34',
      height: '50%'
    }
  },
  {
    month: 'V',
    income: {
      value: '43 586,12',
      height: '35%',
    },
    expenses: {
      value: '53 111,34',
      height: '32%'
    }
  }
  ,
  {
    month: 'VI',
    income: {
      value: '586,12',
      height: '12%',
    },
    expenses: {
      value: '93 111,34',
      height: '55%'
    }
  }
]